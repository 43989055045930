@font-face {
  font-family: 'FuturaPT';
  src: local('FuturaPT'), url('https://dashboard.emailwish.com/assets/fonts/FuturaPTHeavy.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'FuturaPT';
  src: local('FuturaPT'), url('https://dashboard.emailwish.com/assets/fonts/FuturaPTBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;

}

/*@font-face {*/
/*    font-family: 'FuturaPT';*/
/*    src: local('FuturaPT'), url(Assets/fonts/FuturaPTLight.otf) format('truetype');*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*}*/

@font-face {
  font-family: 'FuturaPT';
  src: local('FuturaPT'), url('https://dashboard.emailwish.com/assets/fonts/FuturaPTBook.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;

}

@font-face {
  font-family: 'FuturaPT';
  src: local('FuturaPT'), url('https://dashboard.emailwish.com/assets/fonts/FuturaPT-Demi.woff') format('woff');
  font-weight: 450;
  font-style: normal;

}

.ew-widgets {
  font-size: 15px;
  font-family: FuturaPT,sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #080808;
  background: #fff;
  line-height: 1.65;
  svg{
    font-size: 25.7143px;
  }
  a:empty, article:empty, div:empty, dl:empty, h1:empty,
  h2:empty, h3:empty, h4:empty, h5:empty, h6:empty, p:empty,
  section:empty, ul:empty {
    display: block;
  }
  *, :after, :before {
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .popup-container {
    width: 100%;
    text-align: center;
  }

  .form-submitted {
    width: 100%;
    height: 100%;
    text-align: center;

    h5 {
      margin-top: 10px;
    }

    button {
      margin-top: 10px;
    }
  }

  .form-submitting-error {
    width: 100%;
    height: 100%;
    text-align: center;

    h5 {
      margin-top: 10px;
    }

    button {
      margin-top: 10px;
    }
  }

  .powered-by {
    text-align: right;

    .check-icon {
      width: 100px;
    }

    img {
      width: 150px;
      margin:0

    }
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000a6;
  }

  .popup-overlay-top-left {
    left: 0;
    top: 0;
  }

  .popup-overlay-top-middle {
    left: 50%;
    top: 0;
    transform: translate(-50%);
  }

  .popup-overlay-top-right {
    right: 0;
    top: 0;
  }

  .popup-overlay-middle-left {
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .popup-overlay-middle-right {
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .popup-overlay-bottom-left {
    left: 0;
    bottom: 0;
  }

  .popup-overlay-bottom-middle {
    left: 50%;
    bottom: 0;

    transform: translate(-50%, 0);
  }

  .popup-overlay-bottom-right {
    right: 0;
    bottom: 0;
  }

  .popup-overlay-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .popup-wrapper {
    margin: 18px;
    position: relative;
    animation: 0.5s appearTopAnimation;
  }

  .popup-close-btn {
    position: absolute;
    top: -14.4px;
    right: -14.4px;

    height: 28.8px;
    width: 28.8px;
    border-radius: 50%;
    font-size: 14.4px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: black;
    color: white;

    cursor: pointer;

    outline: none;
    border: none;
  }

  .no-scroll {
    overflow: hidden !important;
  }


  /* ANimation Keyframes */

  @keyframes appearTopAnimation {

    0% {
      opacity: 0.5;
      top: -43.2px;
    }

    100% {
      opacity: 1;
      top: 0;
    }
  }
  input{
    box-sizing: content-box !important;
  }
}


